header,
main,
footer {
  position: relative;
  z-index: 2;
  transition: transform .3s, visibility 0s .3s;

  &.nav-is-visible {
    transform: translateX(-260px);
  }
}


header {
  height: rem-calc(95px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  background: $white;
  padding-left: rem-calc(10px);

    @include breakpoint(medium down) {
      padding-left: rem-calc(10px);
      height: rem-calc(75px);
      border-bottom: 1px solid #ccc;
  }
  @include breakpoint(medium) {
    padding-right: rem-calc(10px);
  }

  .row {
    justify-content: space-between;
    align-content: center;
    height: 100%;
  }

  .logo {
    display: flex;
    align-items: center;
    width: 250px;
  }

  &.is-scroll {
    border-bottom: 1px solid $normal-gray;
  }
}

.navigation {
  margin: 0;
  align-self: center;
  display: none;

  @include breakpoint(medium) {
    display: block;
  }

  li {
    display: inline-block;
    margin: 0 rem-calc(10px);
    font-weight: bold;
    letter-spacing: rem-calc(1px);

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    color: $body-font-color;
    position: relative;
    padding: 0 0 rem-calc(3px) 0;
    text-transform: uppercase;

    &:after,
    &:before {
      transition: all .5s;
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 0;
      content: '';
      background: #aaa;
      height: 2px;
    }

     &:hover:after {
        width: 100%;
      }
  }
}


.nav-trigger {
  /* vertically align its content */
  display: table;
  height: 100%;
  padding: 0 1em;
  font-size: 1rem;
  text-transform: uppercase;
  color: #333;
  font-weight: bold;
  right: 0;
  border-left: 1px solid #ccc;

  @include breakpoint(medium) {
    display: none;
  }

  span {
    /* vertically align inside parent element */
    display: table-cell;
    vertical-align: middle;
  }

  em, em::after, em::before {
    /* this is the menu icon */
    display: block;
    position: relative;
    height: 2px;
    width: 22px;
    background-color: #000;
    backface-visibility: hidden;
  }

  em {
    /* this is the menu central line */
    margin: 6px auto 14px;
    transition: background-color .2s;
  }

  em::before, em::after {
    position: absolute;
    content: '';
    left: 0;
    transition: transform .2s;
  }

  em::before {
    /* this is the menu icon top line */
    transform: translateY(-6px);
  }

  em::after {
    /* this is the menu icon bottom line */
    transform: translateY(6px);
  }

  &.nav-is-visible {
    em {
      background-color: transparent;
    }
    em::before {
      transform: rotate(-45deg);
    }
    em::after {
      transform: rotate(45deg);
    }
  }
}



.header-teaser {
  // height: calc(100vh - 20px);
  background-position: bottom left;
  background-size: cover;
  padding: rem-calc(16px) 0 0 0;

  .row {
    height: 100%;

    @include breakpoint(large) {
      align-items: center;
    }
  }

  .header-teaser__text {

    @include breakpoint(medium down) {
      order: 1;
      @include flex-grid-column(12, 0);
    }

    @include breakpoint(large) {
      @include flex-grid-column(5, 0);
    }
  }

  .header-teaser__img {

    @include breakpoint(medium down) {
      order: 2;
      text-align: center;
      @include flex-grid-column(12, 0);
    }

    @include breakpoint(large) {
      @include flex-grid-column(7, 0);
    }



    img {
      @include breakpoint(medium down) {
        width: 100%;
        max-width: 600px;
      }
    }
  }

  h1 {

    text-transform: uppercase;
    font-weight: bold;
    color: $orange;

    @include breakpoint(medium down) {
      text-align: center;
      padding: 0 rem-calc(20px);
      font-size: rem-calc(26px);
    }

    @include breakpoint(large) {
      margin-left: auto;
      font-size: rem-calc(50px);
    }

    span {
      display: block;
      text-transform: none;
      font-weight: normal;
      color: $body-font-color;

      @include breakpoint(medium down) {
        font-size: rem-calc(18px);
      }

      @include breakpoint(large) {
        font-size: rem-calc(32px);
      }
    }
  }
}

main {
  padding: rem-calc(85px) 0 0 0;
  background: $white;
}


.contact-box {
  @include flex-grid-row();
  border-bottom: 1px solid #D8D8D8;
  padding: 0 0 rem-calc(25px) 0;

  address {
    display: inline-block;
    margin-bottom: rem-calc(7px);
  }
  address, span {
    font-weight: bold;
    font-style: normal;
    display: block;
  }

  span {
    @include breakpoint(medium down) {
      margin-bottom: rem-calc(10px);
    }
  }
}

.page-teaser {
  margin: 0 rem-calc(15px) rem-calc(55px) rem-calc(10px);
  padding: 0;
}

.opentimes {
  @include flex-grid-column(12, 0);
  margin: 0;

  @include breakpoint(large) {
    @include flex-grid-column(6, 0);
  }

  dl {
    margin: 0;
  }

  dt {
    float: left;
    width: 75px;
    clear: both;
  }

  dd {
    float: left;
    width: auto;
  }
}

.contact-info {
  @include flex-grid-column(12, 0);



  @include breakpoint(large) {
    @include flex-grid-column(6, 0);
  }
}

.page {
  padding: rem-calc(45px) 0;

  .row {
    margin-bottom: rem-calc(35px);

    @include breakpoint(large) {
      margin-bottom: rem-calc(65px);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .column {
    @include breakpoint(medium down) {
      margin-bottom: rem-calc(25px);
    }
  }

  h1 {
    font-size: rem-calc(30px);
    text-transform: uppercase;
    margin: 0 0 rem-calc(45px);
    font-weight: 900;
    @include flex-grid-column(12);

    span {
      font-size: rem-calc(20px);
      display: block;
      font-weight: normal;
    }
  }

  h2 {
    font-size: rem-calc(20px);
    font-weight: 700;

    @include breakpoint(large) {
      margin: rem-calc(35px) 0 rem-calc(25px) 0;
    }
  }

  address {
    font-style: normal;
  }

  .contact {
    margin-top: 10px;
  }
}

.service-info {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin: 0;


  li {
    @include flex-grid-column(6, 0);
    display: flex;
    align-items: center;
    border-bottom: 1px solid $normal-gray;
    padding: rem-calc(25px) 0;

    @include breakpoint(small only) {
      margin-right: rem-calc(5px);

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @include breakpoint(medium only) {
      @include flex-grid-column(12, 0);
    }

    svg {
      margin: 0 rem-calc(15px) 0 0;
      flex: 0 0 auto;
      max-width: 100%;
    }
  }
}


.footer-bottom {
  background: #282727;
  padding: rem-calc(35px) rem-calc(10px);

  .row {
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .footer-navigation {
    @include breakpoint(medium down) {
      display: flex;
      width: 100%;

      li {
        @include flex-grid-column(4);
        margin: 0;
        margin-bottom: rem-calc(10px);
      }
    }
  }
}

.footer-navigation {
  margin: 0;

  li {
    display: inline-block;
    text-transform: uppercase;
    margin: 0 rem-calc(10px) 0 0;
    font-weight: 700;

    a {
      color: $white;
    }
  }
}

.mobile-navigation {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 260px;
  background: #2e3233;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
  transform: translateZ(0) translateX(0);
  transition: transform .3s, visibility 0s .3s;
  visibility: hidden;

  &.nav-is-visible {
    visibility: visible;
    transition: visibility 0s 0s;
  }

  li {
    a {
      display: block;
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      color: #fff;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-bottom: 1px solid #3a3f40;
      transform: translateZ(0);
      will-change: transform, opacity;
      transition: transform .3s, opacity .3s;
    }
  }
}


.jobinfo-box {
  background: $body-font-color;
  color: $white;
  padding: rem-calc(25px);

  a {
    color: $white;
  }

  address {
    font-style: normal;
    margin-bottom: 5px;
  }

  dl {
    margin: 0;

    dt {
      float: left;
      width: 75px;
      clear: both;
    }

    dd {
      float: left;
      width: auto;
    }
  }
}


.intro-block-container {
  padding: rem-calc(30px) rem-calc(10px) 0 rem-calc(10px);

  @include breakpoint(large) {
    padding: rem-calc(55px) rem-calc(10px) 0 rem-calc(10px);
  }
}

.intro-block {
  // display: flex;
  @include flex-grid-row();
}

.intro-block__img {

  //@include breakpoint(medium) {
  //  @include flex-grid-column(4, 0);
  //}

  @include breakpoint(large) {
    display: flex;
    @include flex-grid-column(7, 0);
  }
}

.intro-block__text {
  background: $medium-gray;
  padding: rem-calc(30px);

  h2 {
    font-size: rem-calc(22px);
    color: $orange;
    font-weight: bold;

    @include breakpoint(large) {
      font-size: rem-calc(28px);
      margin-bottom: rem-calc(24px);
    }
  }

  h3 {
    font-size: rem-calc(18px);
    font-weight: bold;

    @include breakpoint(large) {
      font-size: rem-calc(22px);
      margin-bottom: rem-calc(24px);
    }
  }

  //@include breakpoint(medium) {
  //  @include flex-grid-column(8, 0);
  //  padding: rem-calc(30px);
  //}

  @include breakpoint(large) {
    @include flex-grid-column(5, 0);
    padding: rem-calc(30px);
  }
}


.container {
  max-width: $global-width;
  width: 100%;
  margin: 0 auto;
}
