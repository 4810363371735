[class$="-teaser"] {
  padding: rem-calc(65px) 0;

  @include breakpoint(large) {
    padding: rem-calc(85px) 0;
  }

  h2 {
    text-align: center;
    width: 100%;
    margin-bottom: rem-calc(50px);
    text-transform: uppercase;
    font-size: rem-calc(28);
    font-weight: bold;

    span {
      display: block;
      text-transform: none;
      font-size: rem-calc(18);
      font-weight: normal;
    }
  }
}

.service-teaser {
  background: url(/img/bg-leistungen.jpg);
  background-position: bottom right;
  background-repeat: no-repeat;
}

.service-block {

  @include breakpoint(medium down) {
    margin-bottom: rem-calc(16px);
  }

  h2 {
    text-transform: uppercase;
    color: $orange;
    font-size: rem-calc(20px);
    letter-spacing: rem-calc(2px);
  }

  ul {
    list-style: circle;

    li {
      margin-bottom: rem-calc(10px);
    }
  }
}

.service-block__text {
  padding: rem-calc(30px) rem-calc(15px);
  background: $medium-gray;
}


.salons-teaser {
  background: #F4F4F4;
}

.salon-block__text {
  padding: rem-calc(30px) rem-calc(16px);
  background: $white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  @include breakpoint(large) {
    flex: 0 0 50%;
    max-width: 50%;
    padding: rem-calc(30px) rem-calc(25px);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.salon-block__img {
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  @include breakpoint(large) {
    flex: 0 0 50%;
    max-width: 50%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
  }

  img {
    width: 100%;
  }
}

.salon-block__item {
  color: $dark-gray;
  margin-bottom: rem-calc(16px);
  display: block;
  background-color: white;

  box-shadow: rgb(193, 196, 208) 0px 2px 8px 0px;
  border-radius: 8px;

  @include breakpoint(large) {
    display: flex;
    margin-bottom: rem-calc(40px);
  }

  h3 {
    font-size: rem-calc(21px);
    color: $orange;
    display: inline-block;
    border-bottom: 1px solid transparent;
    transition: .3s;
  }

  address {
    font-style: normal;
    display: block;
    margin-bottom: rem-calc(20px);
  }

  span {
    margin-top: auto;
  }

  img {
    transition: .3s;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }

    h3 {
      border-bottom: 1px solid $orange;
    }

    span, address {
      color: $dark-gray;
    }
  }
}
.testimonial-teaser {
  background: url(/img/bg-testimonials.jpg);
  background-position: top left;
  background-repeat: no-repeat;

  h2 {
    @include breakpoint(medium) {
      margin-bottom: rem-calc(75px);
    }
  }
}

.testimonial-block {
  position: relative;
  background: $medium-gray;
  padding: rem-calc(28px);

  @include breakpoint(medium down) {
    margin-bottom: rem-calc(56px);
  }

  img {
    position: absolute;
    top: -40px;
    left: 28px;
  }
  h3 {
    color: $body-font-color;
    font-size: rem-calc(20px);
    font-weight: bold;
    padding: 0 0 0 rem-calc(100px);
  }
}

.testimonial-block__text {
  padding: rem-calc(30px) rem-calc(15px);
}