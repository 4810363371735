.footer-top {
  background-image: url('/img/map.svg');
  background-repeat:no-repeat;
  background-position: center center;
  height: 500px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.footer-infobox {
  display: flex;
  margin-bottom: rem-calc(30px);

  @include breakpoint(medium down) {
    flex-flow: column;
  }
}

.footer-top-left,
.footer-top-right {
  width: 350px;

  @include breakpoint(medium down) {
    width: 100%;
  }
}

.footer-top-left {
  background: $secondary-color;
  color: $white;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: rem-calc(25px) rem-calc(40px);

  @include breakpoint(medium) {
    padding: rem-calc(50px) rem-calc(80px);
  }

  // img {
  //   margin-bottom: rem-calc(25px);
  // }

  dl {
    margin: 0;

    dt {
      font-size: rem-calc(13px);
      text-transform: uppercase;
    }
    dd {
      font-size: rem-calc(24px);
      margin-bottom: rem-calc(20px);
      font-weight: bold;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.footer-top-right {
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: rem-calc(25px) rem-calc(40px);

  @include breakpoint(medium) {
    padding: rem-calc(50px) rem-calc(80px);
  }

  span {
    margin-bottom: rem-calc(25px);
  }

  img {
    margin-bottom: rem-calc(25px);

    &:last-child {
      margin-bottom: 0;
    }
  }
  span, img {
    display: block;
  }
}